.Content{
    display: flex;
    padding: 3.5vw 5.5vw;
    gap: 5vw;
}
.Content-Title{
    width: 38vw;
}
.Title{
    font-family: Nunito-Medium;
    font-size: 2.6vw;
    color: white;
}
.Title-span{
    font-family: Nunito-EB;
}
#red{
    color: #BD002A;
}
.Title-text{
    font-family: Nunito-Regular;
    font-size: 1.4vw;
    color: white;
    padding: 1.5vw 0;
}
.Order-registration{
    width: 37.5vw;
    height: auto;
    background-color: white;
    border-radius: 1vw;
    border: 1px solid #DFE1E4;
    padding: 1.2vw;
}
.Order-registration-title{
    font-family: Nunito-Medium;
    color: #0B0106;
    font-size: 01.5vw;
}
.Order-registration-field{
    font-family: Nunito-Regular;
    color: #808080;
    margin-top: 0.7vw;
}
.Order-registration-field-text{
    font-size: 1vw;
    width: 17vw; 
}
.Order-registration-input,.Order-registration-input2{
    font-family: Nunito-Regular;
    font-size: 1vw;
    width: 17vw;
    height: 2.7vw;
    color: #B3B3B3;
    border: 1px solid #DFE1E4;
    border-radius: 00.4vw;
    padding-left: 0.6vw;
}
.block-1{
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap:0.7vw;
}
.Order-registration-input2{
    padding: 0.8vw 0.6vw;
    width: 36vw;
}
.block-3{
    display: flex;
    padding-top: 1.7vw;
    gap: 1vw;
}
#unic-input{
    height: 5.7vw;
}

/* Adaptiv */

@media screen and (max-width: 425px) {
    .Content{
        display: block;
        padding: 9vw 4vw;
    }  
    .Title{
        text-align: start;
        width: 90vw;
        font-family: Nunito-EB;
        font-size: 5vw;
    }
    .Content-Title{
        width: 90vw;
    }
    .Title-span{
        font-family: Nunito-EB;
    }
    #Text{
        display: none;
    }
    .Title-text{
        width: 90vw;
        font-family: Nunito-Regular;
        font-size: 4vw;
        padding: 3vw 0 5vw 0;
    }
    .Order-registration{
        width: 87.5vw;
        border-radius: 3vw;
        border: 1px solid #DFE1E4;
        padding: 2vw;
    }
    .Order-registration-title{
        display: flex;
        justify-content: center;
        font-family: Nunito-Medium;
        color: #0B0106;
        font-size: 5vw;
    }
    .Order-registration-field{
        font-family: Nunito-Regular;
        color: #808080;
        margin-top: 2vw;
    }
    .Order-registration-field-text{
        font-size: 3.9vw;
        width: 80vw; 
    }
    .Order-registration-input,.Order-registration-input2{
        font-size: 3.5vw;
        width: 86vw;
        height: 8vw;
        border: 1px solid #DFE1E4;
        border-radius: 1.5vw;
        padding-left: 0.6vw;
    }
    .block-1{
        display: block;
    }
    .block-3{
        display: flex;
        align-items: center;
        flex-direction: column;
        padding-top: 2.5vw;
        gap: 3vw;
    }
    #unic-input{
        padding: 2vw;
        height: 16vw;
        width: 82.5vw;
    }
    button{
        width: 70vw;
        height: 5.7vh;
        font-size: 4vw;
    }   
}
.empty-field {
    border-color: red;
}
.focused-input {
    color: black;
}