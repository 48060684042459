@font-face {
    font-family: Nunito-Regular;
    src: url(assets/Fonts/Nunito-Regular.ttf);
}
@font-face {
    font-family: Nunito-Medium;
    src: url(assets/Fonts/Nunito-Medium.ttf);
}
@font-face {
    font-family: Nunito-EB;
    src: url(assets/Fonts/Nunito-ExtraBoldItalic.ttf);
}
@font-face {
    font-family: Nunito-Bold;
    src: url(assets/Fonts/Nunito-Bold.ttf);

}
*{
    margin: 0;
    padding: 0;
    outline: inherit;
    text-decoration: inherit;
    color: inherit;
    gap: inherit;
}
.Project{
    max-width: 100vw; /*1920px*/
    background: linear-gradient(160.07deg, #0097B2 0.29%, #37B38A 22.3%, #7BD75A 53.62%);
}
button{
    font-family: Nunito-Regular;
    font-size:1vw;
    width: 15vw;
    height: 3.7vw;
    border-radius: 8vw;
    background-color: #BD002A;
    color: white;
    border: none;
}
button:focus{
    background-color: white;
    border: 1px solid #BD002A;
    color: #BD002A;
}

