#check{
    display: none;
}
.Content-info{
    display: flex;
    align-items: center;
    flex-direction: column;
    gap: 1.2vw;
}
.Info-title{
    width: 50.5vw;
    font-family: Nunito-Medium;
    font-size: 2.5vw;
    display: flex;
    text-align: center;
    color: white;
}
.info-blocks{
    display: flex;
    column-gap: 2.5vw;

}
.info-block1,.info-block2{
    display: flex;
    flex-direction: column;
}
.info-div{
    display: flex;
    align-items: center;
    font-family: Nunito-Medium;
    font-size:1vw;
    width: 31vw;
    height: auto;
    background-color: white;
    border-radius: 1vw;
    padding: 1.5vw;
}
.Icons{
    width: 3vw;
    height: 3vw; 

}
.info-div-text{
    width: 28vw;
}
.Btn{
    display: none;
}

/* Adaptiv */
@media screen and (max-width:425px) {
    .Content-info{
        display: flex;
        align-items: center;
        flex-direction: column;
        gap: 3vw;
    }
    .Info-title{
        width: 92vw;
        font-family: Nunito-Medium;
        font-size: 5.5vw;
        display: flex;
        text-align: start;
    }
    .info-blocks{
        display: block;
    }
    .info-block1,.info-block2{
        display: flex;
        flex-direction: column;
        padding: 1vw 0;
    }
    .info-block2{
        display: none;
    }
    .info-div{
        display: flex;
        align-items: center;
        font-family: Nunito-Medium;
        font-size:3vw;
        width: 85vw;
        height: auto;
        border-radius: 3vw;
        padding: 2vw 1vw;
    }
    .Icons{
        width: 8vw;
        height: 8vw;
        border-radius: 2vw;
    }
    .info-div-text{
        width: 60vw;
    }  
    .Btn{
        display: flex;
        justify-content: center;
        align-items: center;
        color: white;
        height: 10vw;
        margin-top: 5vw;
        background-color: inherit;
        border: 1px solid #FFFFFF;
        border-radius: 5vw;
    }
    .Btn:focus{
        background-color: inherit;
        border: 1px solid #FFFFFF;
        color: white;
    }
    #check:checked ~ .info-block2,#check:checked ~ * .info-block2 {
        display: flex;
        margin-top: 1vw;
    }   
}
