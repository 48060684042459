#footer-check,#footer-check2{
    display: none;
}
footer{
    display: flex;
    justify-content: space-between;
    padding: 5vw 4.5vw;
    margin-top: 11vw;
    height: auto;
    background-color: #1A2E35;
}
.footer-blocks,.footer-blocks2{
    display: flex;
    gap: 3vw;
}
.footer-block,.footer-logo,
.footer-block2{
    display: flex;
    flex-direction: column;
    align-items: start;
    gap: 0.55vw;
    color: white;
}
.icon-conteiner{
    display: flex;
    gap: 1vw;
}
.footer-block-title{
    font-family: Nunito-Medium;
    font-size: 1.08vw;
    padding-bottom: 0.5vw;
}
.footer-block-num{
    font-family: Nunito-Regular;
    position: relative;
    font-size: 1vw;
    display: flex;
    width: auto;
    padding: 0.4vw 0;  
}
.footer-block-num::after {
    position: absolute;
    content: '';
    left: 0;
    bottom: 0;
    display: block;
    width: 100%;
    height: 0.1vw;
    background: white;
}
.footer-block-num::after {
    width: 0;
    transition: 0.3s;
}
.footer-block-num:hover:after {
    width: 100%;
}
#block-text{
    width: 10.06vw; 
}
.footer-block-text,.footer-block-glavtext,
.footer-block-req{
    font-family: Nunito-Regular;
    width: 17.78vw;
    font-size: 1vw;
    padding: 0.2vw 0;
}
.footer-btn{
    display: none;
}
.footer-Img{
    width: auto;
}
.num{
    width: 1.5vw;
    height: 1.5vw;
}


/* Adaptiv */

@media screen and (max-width: 425px) {
footer{
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    padding: 6vw 5vw 5vw 5vw;
    margin-top: 25vw;
    height: auto;
}
.footer-logo{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-bottom: 3vw;
}
.footer-blocks,.footer-blocks2{
    justify-content: space-between;
}
.footer-blocks2{
    margin-top: -18vw;
    gap: 0;
    flex-direction: column;
}
.footer-block,.footer-block2{
    margin-top: 2vw;
}
.icon-conteiner{
    display: flex;
    gap: 4vw;
}
.footer-block-title{
    font-family: Nunito-Medium;
    font-size: 3.5vw;
    padding-bottom: 0.5vw;
}
.footer-block-num{
    display: flex;
    font-size: 3vw;
    width: 33.5vw;
    padding: 0.4vw 0;
}
#block-text{
    width: 30vw; 
}
.footer-block-text,.footer-block-glavtext,
.footer-block-req{
    width: 33.5vw;
    font-size: 2.8vw;
    padding: 0.2vw 0;
}   
.footer-block-req{
    width: auto;
}
#Info{
    display: none;
}
.num{
    display: none;
}
.footer-Img,.footer-logo-img{
    width: 8vw;
}
.footer-btn{
    display: flex;
    justify-content: end;
    font-family: Nunito-Regular;
    width: 34vw;
    font-size: 3vw;
    color: #0298B1;
}
#invis-div1,#invis-div2{
    display: none;
}
#footer-check2:checked ~ #invis-div2,#footer-check2:checked ~ * #invis-div2 {
    display: block;
}
#footer-check:checked ~ #invis-div1,#footer-check:checked ~ * #invis-div1 {
    display: block;
}
}

