header{
    display: flex;
    padding: 0 10vw;
    justify-content: space-between;
    align-items: center;
    background-color: #007F95;
    height: 6.5vw;
    gap: 1.5vw;
    font-family: Nunito-Medium;
}
.Whatsappicon{
    display: none;
}
.logo{
    width: 3vw;
}
nav{
    display: flex;
}
.link{
  position: relative;
  padding-top: 0.9vw;
  height: 2.3vw;
  color: white;
  font-size: 1.4vw;
  overflow: hidden;

}
.link::after {
  position: absolute;
  content: '';
  left: 0;
  bottom: 0;
  display: block;
  width: 100%;
  height: 0.2vw;
  background: white;
}
.link::after {
  width: 0;
  transition: 0.3s;
}
.link:hover:after {
  width: 100%;
}
.menu-button-container {
    display: none;
    height: 100%;
    width: 12vw;
    cursor: pointer;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
#menu-toggle {
    display: none;
}
.menu-button,
.menu-button::before,
.menu-button::after {
  display: block;
  background-color: #fff;
  position: absolute;
  height: 1vw;
  width: 8vw;
  transition: transform 400ms cubic-bezier(0.23, 1, 0.32, 1);
}
.menu-button::before {
  content: '';
  margin-top: -2.4vw;
}
.menu-button::after {
  content: '';
  margin-top: 2.4vw;
}
#menu-toggle:checked + .menu-button-container .menu-button::before {
  margin-top: 0;
  transform: rotate(405deg);
}
#menu-toggle:checked + .menu-button-container .menu-button {
  background: rgba(255, 255, 255, 0);
}
#menu-toggle:checked + .menu-button-container .menu-button::after {
  margin-top: 0;
  transform: rotate(-405deg);
} 

/* Adaptiv */

@media screen and (max-width: 425px) {
  header{
    padding: 0 3vw;
    height: 14vw;
  }
  .logo{
    width: 8vw;
  }
  .Whatsappicon{
    display: flex;
  }
  .menu-button-container{
    display: flex;
  }
  nav{
    display: block;
    position: absolute;
    top: 0;
    margin-top: 14.2vw;
    left: 0;
    flex-direction: column;
    width: 100%;
    justify-content: center;
    align-items: center;
  }
.link{
  font-size: 3.6vw; 
}
  #menu-toggle ~nav >.link {
       height: 0;
       margin: 0;
       padding: 0;
       border: 0;
       transition: height 400ms cubic-bezier(0.23, 1, 0.32, 1);
  }
  #menu-toggle:checked ~nav >.link {
    height: 9.5vw;
    transition: height 400ms cubic-bezier(0.23, 1, 0.32, 1);
  }
  .link {
    display: flex;
    justify-content: center;
    margin: 0;
    padding: 2vw 0;
    width: 100%;
    color: white;
    background-color: #007F95;
  }
  .Links{
    display: flex;
    align-items: center;
  } 
}
