.Cooperation{
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 7vw;
}
.Cooperation-title{
    font-family: Nunito-Bold;
    font-size: 2.5vw;
    color: white;
    margin-bottom: 5vw;
}
.Cooperation-blocks{
    display: flex;
    gap: 0.9vw;
}
.Cooperation-block{
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 2vw 2.5vw 0 2.5vw;
}
.Cooperation-years{
    font-family: Nunito-Bold;
    font-size: 1.9vw;
    color: white;
}
.Cooperation-text{
    font-family: Nunito-Regular;
    font-size:1vw;
    width: 17vw;
    color: white;
    text-align: center;
}
.Img{
    width: 11vw;
    height: 11vw;
    border-radius: 11vw;
}
.Slider{
    display: none;
}

/* Adaptiv */

@media screen and (max-width:425px){
.Cooperation{
    padding-top: 0;
}
.Cooperation-blocks{
    display: none;
}   
.Slider{
    height: 20vw;
    display: block;
}
.Cooperation-title{
    font-size: 6.6vw;
    margin-bottom: 2vw;
}
.Cooperation-years{
    font-size: 3.5vw;
}
.Cooperation-text{
    font-size:2.8vw;
    width: 50vw;
}
}
