.Content-block{
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 10vw;
}
.Content-block-title{
    display: flex;
    justify-content: center;
    font-family: Nunito-Bold;
    font-size: 3vw;
    color: white;
}
.Content-block-Image{
    display: flex;
    align-items: center;
    gap: 5vw;
}
#Image2{
    display: flex;
    flex-direction: row-reverse;
    gap: 8vw;
}
.Numbers{
    font-family: Nunito-Regular;
    font-size: 1.5vw;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 2.5vw;
    height: 2.5vw;
    color: white;
    background-color: #BF3612;
    border-radius: 5vw;
}
.Image-title{
    display: flex;
    gap: 1vw;
}
.block-Image-title{
    display: flex;
    align-items: center;
    gap: 1.5vw;
}
.block-Image-name{
    font-family: Nunito-Bold;
    font-size: 2vw;
    color: white;
    width: 20vw;
}
.block-Image-desc{
    font-family: Nunito-Regular;
    color: white;
    font-size: 1.3vw;
    width: 21vw;
    padding-top: 1.5vw;
}
.Pic{
    width: 29vw;
}
.Line{
    width: 39vw;
    margin-bottom: -1.5vw;
    transform: rotate(12deg);
}
#Line2{
    transform: rotate(-14deg);
}

/* Adaptiv */
@media screen and (max-width:425px) {
.Content-block-title{
    display: flex;
    justify-content: center;
    font-family: Nunito-Bold;
    font-size: 7vw;
    color: white;
}
.Content-block-Image{
    display: flex;
    flex-direction: column;
    gap: 1vw;
}
#Image2{
    display: flex;
    flex-direction: column; 
    gap: 1vw;
}
.Numbers{
    font-family: Nunito-Regular;
    font-size: 3.2vw;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 7vw;
    height: 7vw;
    color: white;
    background-color: #BF3612;
    border-radius: 7vw;
}
.Image-title{
    display: flex;
    gap: 1vw;
}
.block-Image-title{
    display: flex;
    align-items: center;
    gap: 1.5vw;
}
.block-Image-name{
    font-family: Nunito-Bold;
    font-size: 5.2vw;
    width: auto;
}
.block-Image-desc{
    font-family: Nunito-Regular;
    color: white;
    font-size: 5vw;
    width: 82vw;
    padding-top: 1.5vw;
}
.Pic{
    width: 70vw;
}
.Line{
    display: none;
}
#Line2{
    display: none;
}     
}
