.Advantages{
    display: flex;
    flex-direction: column;
   align-items: center;
   padding-top: 7vw;
}
.Advantages-title{
    font-family: Nunito-Bold;
    font-size: 2.9vw;
    color: white;
    padding-top:  2.9vw;
}
.Advanteges-block{
   display: flex;
   flex-direction: row;
   gap: 3vw;
   padding-top: 3vw;
}
.Advanteges-blocks{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 19vw;
    height: 17vw;
    border-radius: 1vw;
    background-color: white;
    gap: 0.9vw;
}
.Advanteges-block-bigtext{
    font-family: Nunito-Medium;
    font-size: 1.9vw;
}
.Advanteges-block-text{
    font-family: Nunito-Regular;
    font-size: 1vw;
    width: 16vw;
    text-align: center;
    color: #666666;
}
.Swiper{
    display: none;
}
.Image{
    width: 7vw;
    height: 7vw;
}

/* Adaptiv */
@media screen and (max-width:425px) {
.Advantages-title{
    display: flex;
    justify-content: center;
    font-size: 6.9vw;
    padding:  2.9vw 0 ;
}
.Advanteges-block{
    display: none;
}
.Advanteges-block-bigtext{
    font-family: Nunito-Bold;
    font-size: 5.5vw;
}
.Advanteges-block-text{
    font-family: Nunito-Medium;
    font-size: 3.5vw;
    width: 60vw;
    text-align: center;
} 
.Swiper{
    display: block;
    margin-left: auto;
    margin-right: auto; 
}
.mySwiper{
    width: 82vw;
    border-radius: 2.78vw;
    height: auto;
}
.Advanteges-blocks {
    display: flex;
    width: 80vw;
    height: 32vh;
    text-align: center;
    justify-content: center;
    align-items: center;
    border-radius: 2.78vw;
    gap: 3.33vw;
}
.swiper-slide img {
    width: 20vw;
    height: 20vw;
}    
}
