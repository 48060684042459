.Tariffs_and_services{
    display: flex;
    flex-direction: column;
    align-items: center;
    color: white;
    padding-top: 9vw;
}
.Tariffs_and_services-title{
    font-family: Nunito-Bold;
    font-size: 2.8vw;
}
.Tariffs_and_services-bigtext{
    font-family: Nunito-Bold;
    margin-bottom: 1vw;
    font-size: 2vw; 
}
.Tariffs_and_services-text{
    font-family: Nunito-Regular; 
    font-size: 1.5vw;
    width: 49vw;
}
.Tariffs_and_services-block{
   display: flex;
   justify-content: space-evenly;
   width: 93vw;
   margin-top: 2.5vw;
   gap: 0.6vw;
}
.Tariffs_and_services-blocks{
    width: 27.2vw;
    border: 0.02vw solid #E6E9F5;
    border-radius: 1vw;
    background-color: white;
}
.Tariffs_and_services-btn{
    width: 25vw;
    height: 3.7vw;
    border-radius:0.2vw;
    background-color: #375A64;
    margin: 1vw;
}
.Tariffs_and_services-btn:focus{
    border: 0.05vw solid #375A64;
    color: #375A64;
}
.Tariffs_and_services-block-text{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    font-family: Nunito-Medium;
    font-size: 1.3vw;
    width: 25.8vw;
    height: 5.4vw;
    padding: 0.7vw;
    color: #262626;
    border-block: 0.03vw solid #E6E9F5;
}

/* Adaptiv */
@media screen and (max-width: 425px) {
.Tariffs_and_services{
    display: flex;
    flex-direction: column;
    color: white;
    padding: 9vw 4vw;
}
.Tariffs_and_services-title{
    font-family: Nunito-Bold;
    font-size: 8.4vw;
}
.Tariffs_and_services-bigtext{
    display: none;
}
.Tariffs_and_services-text{
    text-align: center;
    font-family: Nunito-Regular; 
    font-size: 4vw;
    width: 89vw;
    list-style-type: none;
    padding: 2vw 0 7vw 0;
}
.Tariffs_and_services-block{
    display: none;
}
.Tariffs_and_services-blocks{
    width: 81vw;
    border-radius: 3vw;
}
.Tariffs_and_services-btn{
    width: 77vw;
    height: 13vw;
    border-radius:2vw;
    margin: 2vw;
}
.Tariffs_and_services-block-text{
    font-size: 3vw;
    width: auto;
    height: 8vh;
    border-block: 0.03vw solid #E6E9F5;
}
.mySwiper2{
    width: 82vw;
    border-radius: 2.78vw;
    height: auto;
}    
}
