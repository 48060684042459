.For_stores_and_organizations{
    display: flex;
    align-items: center;
    flex-direction: column;
    list-style-position: inside;
    text-align: center;
    padding-top: 4vw;
}
.Fsao-title{
    font-family: Nunito-Bold;
    font-size: 2.5vw;
    color: white;
    padding-bottom: 1.9vw;
}
.Fsao-text,.Fsao-text2{
    font-family: Nunito-Regular;
    height: auto;
    font-size: 1.4vw;
    color: white;
}
.Fsao-text2{
    display: none;
}
.Fsao-content{
    display: flex;
    gap: 6vw;
    margin: 2vw 0;
}
.Fsao-info{
    display: flex;
    align-items: start;
    width: auto;
    height: auto;
    border-radius: 0.9vw;
    padding: 0.9vw 0.7vw;
    background-color: white;
    gap: 1vw;
}
.Fsao-infoblock{
    display: flex;
    flex-direction: column;
    text-align: start;
}
.Fsao-infotitle{
  font-family: Nunito-Bold;
  font-size: 1.9vw;
}
.Fsao-infotext{
    font-family: Nunito-Medium;
    width:28vw;
    font-size: 1vw;
    color: #404040;
}
.Fsao-endtext,.Fsao-endtext2{
    font-family: Nunito-Regular;
    font-size: 1.5vw;
    width: 59vw;
    color: white;
}
.Fsao-num{
    display: none;
}
#info-text{
    display: none;
}
.Fsao-endtext{
    display: none;
}
#Big-text{
    display: block;
    font-size: 2vw;
}
.Fsao-endtext2{
    display: block;
}
.Fsao-Img{
    width: 5vw;
}
/* Adaptiv */

@media screen and (max-width: 425px) {
.For_stores_and_organizations{
    display: flex;
    padding: 4vw 5vw;
}
.Fsao-title{
    text-align: start;
    font-size: 5.5vw;
} 
.Fsao-content{
    display: block;    
}
.Fsao-info{
    width: 70vw;
    border-radius: 3vw;
    margin: 3vw 8.5vw;
    padding: 2vw;
}
.Fsao-Img{
    width: 8vw;
}
.Fsao-infotitle{
    font-size: 4.9vw;
}
.Fsao-infotext{
    width: auto;
    font-size: 2.8vw; 
}
.Fsao-text{
    display: none;
}
.Fsao-text2{
    display: flex;
    flex-direction: column;
    font-size: 4vw;
    list-style-type: none;
    width: 85vw;
    padding: 4vw 0;
}
.Fsao-num{
    padding-top: 2vw;
    font-family: Nunito-Regular;
    font-size: 3.5vw;
    display: block;
}
#info-text{
    display: block;
}
#Fsao-num{
    width: 4vw;
}
.Fsao-endtext{
    display: block;
    font-size: 4vw;
    width: 90vw;
}
.Fsao-endtext2{
    display: none;
}
#Big-text{
    font-size: 5vw;
}
}
