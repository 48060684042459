#isexpanded,#isexpanded2{
    display: none;
}
.Delivery_and_payment-block{
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 16vw;
}
.Delivery_and_payment{
    display: flex;
    gap: 7vw;
    padding-top: 2.5vw;
}
.Delivery_and_payment-title{
    font-family: Nunito-Bold;
    font-size: 2.4vw;
    color: white;
}
.Delivery,.Payment{
    width: 35.7vw;
    height: auto;
    border-radius: 0.8vw;
    padding: 0.8vw;
    background-color: white;
    gap: 2.5vw;
}
.Delivery-header,.Payment-header{
    display: flex;
    align-items: center;
    height: auto;
    gap: 0.5vw;
}
.Delivery-title,.Payment-title{
    font-family: Nunito-EB;
    font-size: 2vw;
}
.Delivery-text,.Payment-text{
    font-family: Nunito-Bold;
    font-size: 1.1vw;
    padding-left: 5.5vw;
    color: #404040;
}
.Payment-text{
    display: flex;
    flex-direction: column;
    width: 25vw;
    gap: 0.5vw;
    padding-left: 7vw;
}
.Delivery-text2,.Payment-text2{
    display: flex;
    flex-direction: column;
    padding-top: 1vw;
}
.Payment-text2{
    padding-top: 0;
}
#Payment-p{
    margin-left: -1.3vw;
}
.Payment-link{
    text-decoration: underline;
}
.DAP-Img{
    width: 5vw;
}
.DAP-Btn{
    display: none;
    font-family: Nunito-Regular;
    font-size: auto;
    width: 117px;
    height: 26px;
  background-color: inherit;
  color: #0298B1;
}
.DAP-Btn:focus{
    border: none;
    background-color: inherit;
    color: #0298B1;
}

/* Adaptiv */

@media screen and (max-width:425px) {
.Delivery_and_payment-title{
    font-size: 7.7vw;
}  
.Delivery_and_payment{
    display: flex;
    flex-direction: column;
}
.DAP-Img{
    width: 11.11vw;
    height: 9.1vh;
}
.Delivery-title,.Payment-title{
    font-size: 5vw;
}
.Delivery,.Payment{
    display: block;
    width: 75vw;
    border-radius: 2.5vw;
    padding: 2vw 1vw 5vw 1vw;
    background-color: white;
    gap: 2.5vw;
}
.Delivery-header,.Payment-header{
    display: flex;
    align-items: center;
}
.Delivery-text,.Payment-text{
    font-family: Nunito-Bold;
    font-size: 4vw;
    padding: 0 5vw;
    color: #404040;
}
#isexpanded,#isexpanded2,.Delivery-text2,.Payment-text2{
    display: none;
}
.Payment-text{
    display: flex;
    flex-direction: column;
    width: auto;
    gap: 0vw;
    list-style-type: none;
}
.DAP-Btn{
    width: auto;
    height: auto;
    padding: 5vw 0 2vw 0;
    display: flex;
    justify-content: center;
}
#isexpanded:checked ~ .Delivery-text2,
#isexpanded:checked ~ * .Delivery-text2 {
    display: block;
}
#isexpanded2:checked ~ .Payment-text2,
#isexpanded2:checked ~ * .Payment-text2 {
    display: block;
}   
#Payment-p{
    margin-left: 0;
}
}
